import React, {ChangeEvent, useEffect, useId, useState} from 'react';
import FontPicker from "font-picker-react";
import '../scss/customFont.scss'
import {Variant} from "@testing-library/react";
interface rangeProps{
    propertyName: string;
    cssProperty:string;
    fontWeight:any;
    fontProperty:any;

}
const CustomFont: React.FC<rangeProps> = (props) => {
    const [customFont, setCustomFont] = useState('Bebas Neue');
    const propID = props.propertyName.replace(/ /g, '');

    useEffect(() => {
        setCustomFont(customFont);
        props.fontProperty(customFont);


            document.documentElement.style.setProperty(
                props.cssProperty,
                customFont
            );

    }, [customFont]);

    const updateFont = (nextFont:any) =>{
        setCustomFont(nextFont.family)
    }

    let selectedWeight;

    if (props.fontWeight == "400"){
        selectedWeight = "regular"
    }else{
        selectedWeight = props.fontWeight;
    }

    return(
        <div className="m-customfont">
            <div className="e-title">
                {props.propertyName}
            </div>
            <div className="e-selector">
                <FontPicker
                    apiKey="AIzaSyBNA9hvJvXrgp5LDO9hHVf84Zj-1LAzjws"
                    activeFontFamily={customFont}
                    onChange={updateFont}
                    pickerId={propID}
                    variants={[selectedWeight]}
                    limit={100}
                />
            </div>
        </div>
    )
}

export default CustomFont;