import React, {ChangeEvent, useEffect, useId, useState} from 'react';
import '../scss/numberInput.scss'
interface rangeProps{
	propertyName: string;
	maxAmount:number;
	minAmount:number;
	value:number;
	numberProperty:any;
}
const NumberInput: React.FC<rangeProps> = (props) => {
	const [numberValue, setNumberValue] = useState<number>(props.value);
	const propID = props.propertyName.replace(/ /g, '');

	useEffect(() => {
		setNumberValue(numberValue);
		props.numberProperty(numberValue);
	}, [numberValue]);

	function updateInput(event:ChangeEvent<HTMLInputElement>){
		const inputTarget = event.target;
		setNumberValue(Number(inputTarget.value));
	}

	function reset(event:any){
		if(event.detail === 2){
			setNumberValue(props.value);
		}
	}


	return(
	  <div className="m-numberinput">
		  <div className="e-title">
			  <label htmlFor={propID}>
				  {props.propertyName}:
			  </label>
		  </div>
		  <div className="e-input" onClick={reset} title="Double Click to Reset">
			  <input type="Number"
					 id={propID}
					 value={numberValue}
					 onChange={updateInput}
					 min={props.minAmount}
					 max={props.maxAmount}
			  />
		  </div>
	  </div>
	)
}

export default NumberInput;