import React, {useState} from 'react';
import './scss/styles.scss'
import ColourPicker from './components/colourPicker';
import RangeSlider from "./components/rangeSlider";
import CustomFont from "./components/customFont";
import Footer from "./components/Footer"
import logo from "./components/images/logo.png"
import Panel from "./components/images/ChatStyler-Panel.png"
import AitumLogo from "./components/images/AitumLogo.png"
import AitumSnapshot from "./components/images/AitumSnapshot.png"
import NumberInput from "./components/numberInput";

function App() {
    //set output for user properties in code
    const [timerDirection, setTimerDirection] = useState('CountDown')
    const [timerLoop, setTimerLoop] = useState('false');
    const [timerFont, setTimerFont] = useState('Bebas Neue');
    const [timerBackground, setTimerBackground] = useState('none');
    const [timerColour, setTimerColour] = useState('#ff0000');
    const [timerSize, setTimerSize] = useState('16');
    const [timerWeight, setTimerWeight] = useState('400');
    const [timerBorderRadius, setTimerBorderRadius] = useState('0');
    const [timerHours, setTimerHours] = useState(0);
    const [timerMinutes, setTimerMinutes] = useState(10);
    const [timerSeconds, setTimerSeconds] = useState(30);

    let urlColour = timerColour.replace('#', '')
    let urlBackground = timerBackground.replace('#', '')

    let convertedHours = timerHours * 3600;
    let convertedMinutes = timerMinutes * 60;
    let totalTime = convertedHours + convertedMinutes + timerSeconds;

    const timerFontCode = timerFont.replace(/ /g, '+');

    const [copiedURL, setCopiedURL] = useState(false);

    const copyURL = () => {
        let timerURL: string = document.getElementById('timerURL')?.innerText as string;
        navigator.clipboard.writeText(timerURL)

        setCopiedURL(true);
        setTimeout(() => {
            setCopiedURL(false);
        }, 2000);
    }

    function directionSet() {
        const directionCheckbox = document.getElementById("directionOutput") as HTMLInputElement;

        if (directionCheckbox.checked) {
            setTimerDirection('CountUp');
        } else {
            setTimerDirection('CountDown');
        }

        return (
          timerDirection
        )
    }

    function loopSet() {
        const loopCheckbox = document.getElementById("loopOutput") as HTMLInputElement;
        console.log(timerLoop);
        if (loopCheckbox.checked) {
            setTimerLoop('true');
        } else {
            setTimerLoop('false');
        }

        return (
          timerDirection
        )
    }

    return (
        <>
            <header className="l-intro">
                <div className="container">
                    <div className="e-image">
                        <img src={logo} alt=""/>
                    </div>
                    <h1>Browser Timer</h1>

                    <p className='preamble'>
                        Welcome to the browser timer, create the timer that <strong>YOU</strong> want.
                    </p>
                    <p>
                        Customise your timer below, the background colour fills the whole background of the timer. Once done, copy the URL by clicking on it and paste it into your Browser Source.
                    </p>
                </div>
            </header>
            <div className="container main">
                <div className="row">
                    <div className="l-left">
                        <div className="e-form">
                            <h2>Customise Timer</h2>

                            <div className="form-row">

                                <div className="timeset">
                                    <NumberInput  propertyName="Hours" maxAmount={1000}
                                                  minAmount={0} value={timerHours} numberProperty={setTimerHours}/>
                                    <NumberInput  propertyName="Minutes" maxAmount={60}
                                                  minAmount={0} value={timerMinutes} numberProperty={setTimerMinutes}/>
                                    <NumberInput  propertyName="Seconds" maxAmount={60}
                                                  minAmount={0} value={timerSeconds} numberProperty={setTimerSeconds}/>
                                </div>
                                <div className='toggle'>
                                    <input type="checkbox" id="directionOutput" onChange={directionSet}/>
                                    <label htmlFor="directionOutput">Count Up</label>
                                </div><div className='toggle'>
                                    <input type="checkbox" id="loopOutput" onChange={loopSet}/>
                                    <label htmlFor="loopOutput">Loop Timer</label>
                                </div>
                                <CustomFont propertyName="Font" cssProperty='--userFont'
                                            fontProperty={setTimerFont}
                                            fontWeight={timerWeight}/>
                                <ColourPicker propertyName="Color" cssProperty='--userColor' default='#FFFFFF'
                                              colourProperty={setTimerColour}/>
                                <ColourPicker propertyName="Background" cssProperty='--userBackground'
                                              default='transparent' colourProperty={setTimerBackground}/>
                                <RangeSlider propertyName="Font Size" cssProperty="--userFontSize" maxAmount={320}
                                             minAmount={12} steps={1} value='250' rangeProperty={setTimerSize}/>
                                <RangeSlider propertyName="Border Radius" cssProperty="--userBorderRadius"
                                             maxAmount={80}
                                             minAmount={0} steps={1} value='0' rangeProperty={setTimerBorderRadius}/>

                                <div className="copyurl">
                                    URL
                                    <div className='innerurl' onClick={copyURL} >
                                        <span id='timerURL'>https://timer.colinhorn.co.uk/timer?time={totalTime}&colour={urlColour}&size={timerSize}&background={urlBackground}&radius={timerBorderRadius}&font={timerFontCode}&direction={timerDirection}&loop={timerLoop}</span>
                                        <button className='btn copybutton' title='Copy'>
                                            <span className={copiedURL ? "e-copy copied" : "e-copy"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path
                                                  d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="l-right">
                        <iframe src={`timer.html?time=${totalTime}&colour=${urlColour}&size=${timerSize}&background=${urlBackground}&radius=${timerBorderRadius}&font=${timerFontCode}&direction=${timerDirection}&loop=${timerLoop}`}></iframe>
                    </div>
                </div>
            </div>
            <section className="m-support">
                <div className="container">
                    <div className="row centre-v">
                        <div className="panel">
                            <div className="e-panelwrapper">
                                <a href="https://chatstyler.colinhorn.co.uk" target="_blank">
                                    <img src={Panel} alt=""/>
                                </a>
                            </div>
                        </div>
                        <div className="text">
                            <h2>Chat Styler</h2>

                            <p>If you want to customise the full look of your chat overlay check out the Chat Styler. A fully customisable generator for you and your stream.</p>
                            <p>
                                <a href="https://chatstyler.colinhorn.co.uk" target="_blank" className="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"/></svg>
                                    Style Your Chat
                                </a>
                            </p>
                        </div>

                        <div className="panel">
                            <img src={AitumSnapshot} className="aitum-snapshot" alt=""/>
                        </div>
                        <div className="text">
                            <img src={AitumLogo} alt=""/>

                            <p>Need to automate your stream? Create fun channel point redemptions? Check out Aitum. Take your stream to the next level!</p>
                            <p>
                                <a href="https://www.aitum.tv" target="_blank" className="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                                        <polygon className="st0" points="426.47,73.24 926.46,573.22 926.46,73.24 "/>
                                        <polygon className="st0" points="73.54,719.33 573.53,219.35 73.54,219.35 "/>
                                        <polygon className="st0" points="926.46,573.22 219.37,573.22 572.92,926.76 "/>
                                    </svg>
                                    aitum.tv Website
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default App;
